import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function ChgRegOfc() {
  /* Slider */
  // const ChgRegOfcSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     background: `linear-gradient(139.4deg, rgb(13, 50, 77), rgb(127, 90, 131))`,
  //     header: `Change in Registered Office`,
  //     content: `India's Best Change in Registered Office Advisory Service`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag:
  //       "Best Change in Registered Office Advisory Service Near Bangalore",
  //   },
  // ];
  /* Package Data */
  const PackageCompData = {
    header: `Change Of Registered Office`,
    buyBtnLink: `#pricing-buy`,
    price: `3499`,
    content: [
      {
        points: `Share Certificate
        Book`,
        icon: "/imgs/icons/bregistration/form.png",
        alt_tag: "Trademark Search Report",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Minutes Book &
        Statutory Register`,
        icon: "/imgs/icons/bregistration/itr.png",
        alt_tag: "Discount",
        background: "bg-c679e3",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
      },
    ],
  };

  /* About Data With Form*/
  const ChgRegOfcAboutData = {
    header: `Change in Registered Office`,
    sub_title: `Advisory service for change in registered office`,
    content_paragraph: [
      // `All communications related to the company will take place at
      //   the registered office of the business. A corporation may
      //   have administrative, corporate, branch, warehouse, and
      //   factory offices, but the registered office is the location
      //   that is the most crucial or the spot where all business
      //   begins.`,

      `Corporation does not have to be registered with the
        Registrar of Companies in order to register the location of
        its registered office. Promoters of the firm are the members
        who, after considering a number of variables, choose the
        location and location where the registered office will be
        held. Any modifications to the Registered Office location
        after it has been formally declared by filing INC 22 must be
        reported to the ROC.`,
    ],
  };

  /* Scroll Nav Data */
  const ChgRegOfcScrollId = [
    {
      id: `#types`,
      heading: `Types`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#form`,
      heading: `Application Forms`,
    },
    {
      id: `#documents`,
      heading: `Documents`,
    },
  ];

  /* Img Content Component Data */
  const ChgRegOfcIcData = {
    id: "types",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Types of Registered Office Address Change",
    image: "/imgs/registration/ngo/fcra-registration.png",
    alt_tag: "Types of Registered Office Address Change",
    Paragraph: ``,
    points: [
      {
        content: `The following procedure must be followed when a
        company moves its registered office:`,
        icon: true,
      },
      {
        content: `Change in a company's registered office inside the
          same state but outside of the current city, village,
          or town that is within the same Registrar of Companies
          jurisdiction (ROC).`,
        icon: true,
      },
      {
        content: `Company's Registered Office moving within the same
          state.`,
        icon: true,
      },
      {
        content: `Change in a Private Limited Company's Registered
          Office from one ROC to another ROC or Registrar of
          Companies within the same state.`,
        icon: true,
      },
      {
        content: `Changing a company's registered office from one state
          or one ROC to another, or from one ROC to another.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ChgRegOfcCfData = {
    id: "procedure",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Procedure for change registered office",
    content: `The following procedure must be followed when a
      company moves its registered office:`,
    body: [
      {
        points: `The date, time, and location of the EGM, or
          extraordinary general meeting, are set during the
          board meeting.`,
        icon: true,
      },
      {
        points: `In the EGM, or Extra-Ordinary General Meeting, a
          decision or resolution on the change in registered
          office address is approved.`,
        icon: true,
      },
      {
        points: `Within 30 days of the day the judgement or resolution
          was passed, Form MGT-14 must be submitted to the
          Registrar of Companies.`,
        icon: true,
      },
      {
        head: `Form MGT – 14:`,
        points: `Within 15 days following the resolution's passage, a
          certified copy of the resolution must be filed with
          Form INC-22 to the Registrar of Companies.`,
        icon: true,
      },
      {
        head: `Form INC – 22:`,
        points: `Duplicate of the company's address for its registered
          office.`,
        icon: true,
      },
      {
        points: `Copy of a recent utility bill, no more than two months
          old.`,
        icon: true,
      },
      {
        points: `Obtaining a No Objection Certificate, or NOC, from the
          property owner.`,
        icon: true,
      },
      {
        points: `The board meeting approved a special resolution.`,
        icon: true,
      },
    ],
  };

  /* Slider */
  const imgSlider = [
    "/imgs/features.png",
    "/imgs/assited/pre-incorpration.png",
    "/imgs/business-plan.png",
  ];

  /*  Slider Content Component Data */
  const ChgRegOfcScData = {
    id: "form",
    background: [],
    mt_div: "mt-3",
    mt_img: "mt-5",
    header: "Regional Director Application on Form INC-23",
    paragraph: "",
    content: [
      {
        points: `Place an ad in the local daily newspaper in the
          district where the registered office is located in
          both the vernacular and the English language.`,
        icon: true,
      },

      {
        points: `An individual notice describing the subject of the
          application and notifying anyone who expresses
          interest in the suggested modification to the MOA is
          delivered to each debenture holder, creditor, and
          depositor of the company.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /*  Content Img Component Data */
  const ChgRegOfcCiData = {
    id: "documents",
    background: "main-content",
    mt_div: "mt-3",
    header: "Documents Required for registered office change",
    paragraph: ``,
    points: [
      {
        content: `Extraordinary general meeting notice that has been
          certified.`,
        icon: true,
      },
      {
        content: `Decision approved at EGM in a certified copy with Modified MoA copy.`,
        icon: true,
      },
      {
        content: `Declaration in support of the application with printed advertisement copy from the newspaper.`,
        icon: true,
      },
      {
        content: `List of investors, debtors, and creditors entitled to
          objections.`,
        icon: true,
      },
      {
        content: `Any two Directors may sign a declaration report
          stating that the business has not fallen behind on
          paying its employees.`,
        icon: true,
      },
      {
        content: `Duplicate of Registered office address,for the usage of the premises, the owner must issue a
          NOC, or no objection certificate.`,
        icon: true,
      },
      {
        content: `Certificate of Declaration from Key Managerial
          Personnel,and copy of the registered premises' utility bills.`,
        icon: true,
      },
    ],
    image: "/imgs/business/dsc1-lib.png",
    alt_tag: "Required Documents for Change of registered Office ",
  };

  var FAQ_data = [
    {
      header: `Where is the company's registered office?`,
      body: [
        {
          content: `All official correspondence pertaining to a corporation is sent to
          the registered office of the company.`,
          icon: false,
        },
      ],
    },

    {
      header: "Can I modify the company's registered office?",
      body: [
        {
          content: `All formal communications pertaining to a corporation are
          communicated from the registered office of the company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "How long does it take to change the address of the registered office?",
      body: [
        {
          content: `Any application form to modify a registered office address is
          typically processed in two working days.`,
          icon: false,
        },
      ],
    },
    {
      header: "Can two or more companies have the same registration address?",
      body: [
        {
          content: `It is possible for two businesses to share a registered address.`,
          icon: false,
        },
      ],
    },
    {
      header: "Are a registered office and a head office the same thing?",
      body: [
        {
          content: `Every business has a central location that embodies the essence of
          the operation and serves as the physical location of the company
          in question. This is known as the company's registered office or
          head office when dealing with a company, and its partner,
          partnership, or other business form.`,
          icon: false,
        },
      ],
    },
    {
      header: "Is it simple to change a registered office?",
      body: [
        {
          content: `The proper registration procedure varies from case to case and
          depends on several factors. When compared to other processes for
          moving registered office addresses, the process will be simple if
          the new registered office is located within the same city.`,
          icon: false,
        },
      ],
    },
    {
      header: "What is Registered Office?",
      body: [
        {
          content: `The registered office is the principal place of business, the registered office will 
          be used for all the official communication.`,
          icon: false,
        },
      ],
    },
    {
      header: "Can the address of the registered office be changed?",
      body: [
        {
          content: `Yes, the registered office of a company can be shifted from one place to 
          another in the same state or from one state to another. It is a legal procedure that can be completed online.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "What are the circumstances under which the company can change the registered office address?",
      body: [
        {
          content: `The company can change address under three circumstances Change of the company registered within 
          the same village or city Change within the same state Change from one state to another.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "Is permission from the shareholders required to change the address of the registered office?",
      body: [
        {
          content: `No the companies that are relocating to another location in the same village, towns, or city 
          limits d not need permission from their shareholders or any other authorities But it is necessary to notify
           the ROC of the changes that are made in Form INC 22.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Change in Registered Office'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ChgRegOfcSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ChgRegOfcAboutData} />
          <Counter />
          <ScrollNav scroll_data={ChgRegOfcScrollId} />
          <ImgContent item={ChgRegOfcIcData} />
          <ContentForm CFSection_data={ChgRegOfcCfData} />

          <SliderContent ScSection_data={ChgRegOfcScData} />
          <ContentImg CISection_data={ChgRegOfcCiData} />

          <Cta />
          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
